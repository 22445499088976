.item {
    margin-left: 4px;
}

.skeleton {
    padding: 8px 150px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
}

.description {
    max-width: 15vw;
    padding: 15px;
    font-size: 16px;
}

@media screen and (max-width: 1200px) {
    .description {
        max-width: 60vw;
        padding: 15px;
        font-size: 16px;
    }
}

