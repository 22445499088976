.content {
    max-width: 850px;
    line-height: 1.6;
    font-size: 18px;
    margin: 25px auto;
}

.math {
    max-width: 850px;
    display: block;
}

@media screen and (max-width: 900px) {
    .content {
        max-width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        line-height: 35px;
        font-size: 20px;
    }

    .math {
        max-width: 90vw;
    }
}