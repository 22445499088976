.section > * {
    width: 100%;
}

.section > input, .section > textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.alert {
    padding: 15px;
    border-radius: 3px;
    color: white;
}

.alert.error {
    background: #f08080;
}

.alert.success {
    background: #90ee90;
}

.content {
    max-width: 850px;
    line-height: 1.6;
    font-size: 18px;
    margin: 25px auto;
}

.content.dark input, .content.dark textarea {
    background: #2c2c2f;
    color: white;
}

.submit {
    border-radius: 5px;
    border: 0;
    margin-top: 6px;
    padding: 6px;
    background: #f4f4f4;
}

.label {
    font-size: 0.9em;
}