.filters {
    display: flex;
    flex-direction: row;

    margin: 15px auto;
    align-items: baseline;
    flex-wrap: wrap;
}

.filterText {
    font-size: 1.1em;
}

.filter > .search {
    width: 100%;
    height: 100%;
    border: 1px solid hsl(0, 0%, 80%);
    padding: 8px;
    border-radius: 4px;
    color: inherit;
}

.filter.dark > .search {
    border: 1px solid hsl(0, 0%, 31%);
    background: #2c2c2f;
}

.filter.dark > .search::placeholder {
    color: #e5e5f1;
}

.filter {
    min-width: 300px;
    flex-grow: 1;
    margin-left: 15px;
}

@media (min-width: 1300px) {
    .filters {
        width: 1260px;
    }
}

@media (max-width: 1300px) {
    .filters {
        width: 90%;
    }
}


