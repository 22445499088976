.tag {
    background: #5b6e7e;

    font-size: 12px;
    color: white;

    padding: 2px 6px;
    margin: 2px;

    border: none;
    border-radius: 3px;

    min-width: 24px;
    min-height: 20px;
}

.tag.dark {
    background: #e5edfa;
    color: black;
}

.tag.minimal {
    background: transparent;
    border: 1px solid #5b6e7e;
    color: #5b6e7e;
}

.tag.minimal.dark {
    border: 1px solid #e5e5f1;
    color: #e5e5f1;
}

.tag.minimal.interactive:hover {
    color: white;
}

.tag.minimal.interactive.dark:hover {
    color: black;
}

.tag.interactive {
    cursor: pointer;
}

.tag.interactive:hover {
    background: #6b8294;
}

.tag.interactive.dark:hover {
    background: #c6d0d9;
}