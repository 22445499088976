.button {
    background: none;
    border: 2px solid lightgray;
    border-radius: 2px;
    width: auto;
    padding: 8px 10px;
    margin: 4px;
    cursor: pointer;
}

.button.dark {
    color: white;
}

.button.minimal {
    background: none;
    border: none;
}

.button:hover {
    background: rgb(245, 245, 245);
}

.button.dark:hover {
    background: rgb(61, 61, 61);
}

.icon > svg {
    vertical-align: middle;
    fill: black;
    stroke-width: 1px;
    width: auto;
    height: 100%;
    margin-right: 5px;
    margin-top: auto;
    margin-bottom: 3px;
}

.icon.dark > svg {
    fill: white;
}