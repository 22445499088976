@media (max-width: 800px) {
    .math-container {
        max-height: 100vh;
    }
}

.math-container {
    padding-bottom: 15px;
    padding-top: 15px;
    overflow: auto;
    max-width: 800px;
    display: block;
}

.inline {
    display: inline;
}

.blockquote {
    padding: 10px;
    margin: 0 0 1em;
    overflow: auto;
}

.blockquote.light {
    border-left: 2px solid #fce88c;
    background: #fcf5d9;
}

.blockquote.dark {
    border-left: 2px solid #154c6f;
    background: #30312c;
}