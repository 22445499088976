.comment {
    max-width: 100%;
    position: relative;
    left: 25px;
    min-height: 100px;
    margin-top: 20px;
    padding: 5px;
    width: calc(100% - 20px);
}

.level-1 {
    left: 0;
}

.header > * {
    display: inline;
    margin-right: 5px;
    position: relative;
}

.date {
    margin-top: 3px;
    font-size: 0.8em;
    float: right;
}

.name {
    margin-top: 0;
    margin-bottom: 0;
}

.reply {
    font-size: 0.8em;
    text-align: right;
}

.content {
}

.content_wrapper {
    border: 1px solid black;
    margin-left: 95px;
    border-radius: 5px;
    padding: 5px 15px;
    position: relative;
}

.picture_col {
    width: 80px;
    height: 80px;
    float: left;
    margin-left: 0;
    margin-top: 10px;
    text-align: center;
}

.nested {
    border-left: 1px solid #dcdcdc;
}

.content-wrapper-skeleton {
    width: calc(100% - 120px);
    position: relative;
    margin-left: 95px;
    height: 80px;
}

.profile_picture {
}