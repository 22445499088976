html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    text-transform: none;
    line-height: 1.28581;
    letter-spacing: 0;
    font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", "Icons16", sans-serif;
    font-size: 14px;
    font-weight: 400;
}

.App {
    min-height: 100vh;
    width: 100%;

    transition: background-color .5s, color .5s, border .5s;
}

body.light, .App.light {
    color: #182026;
    background: white;
}

body.dark, .App.dark {
    color: #e5e5f1;
    background: #292a2d;
}

.AppContent {
    height: 100%;
}

body.dark a, .App.dark a {
    color: #6394b3;
}

p {
    margin-top: 0;
    margin-bottom: 10px;
}

small {
    font-size: 12px;
}

strong {
    font-weight: 600;
}

a {
    text-decoration: none;
    color: #106ba3;
}

a:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #106ba3;
}

a code {
    color: inherit;
}

.footer {
    margin-top: 30px;
    font-family: 'Dosis', sans-serif;
    width: 100%;
    text-align: center;
}

.bp3-dialog.bp3-alert, .bp3-dialog.bp3-alert .bp3-alert-body {
    max-width: 100vw;
    width: auto;
}

.AppContent {
    padding-left: calc(100vw - 100%);
}