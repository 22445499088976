.container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    width: 90vw;
}

@media (max-width: 600px) {
    .container {
        width: 100vw;
        margin-left: 8px;
        margin-right: 8px;
    }
}

.preview:hover {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08)
}

.preview {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08), 0 0 0 1px rgba(0, 0, 0, 0.04);
    position: relative;
    padding: 5px 20px;
    margin: auto auto 15px;
    max-width: 1260px;
    width: 1260px;
    max-height: 52vh;
    overflow-y: hidden;
}

@media (max-width: 600px) {
    .preview {
        width: 90%;
        max-width: 90%;
        min-width: 90%;
        max-height: 450px;
    }
}

@media (min-width: 2000px) {
    .preview {
        box-sizing: border-box;
        /*flex-grow: 1;*/
        max-width: 46%;
        min-width: 46%;
        width: 46%;
        /*overflow: hidden*/
        margin-left: 2%;
        margin-right: 2%;
    }
}
