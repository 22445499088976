.scrollSpy {
    position: fixed;
    top: 80px;
    left: 25px;
    line-height: 22px;
    transition: opacity .5s;
    opacity: 0.5;
    max-height: 70%;
    overflow-y: hidden;
}

.scrollSpy:hover {
    z-index: 100;
    overflow-y: auto;
    opacity: 1;
}

@media screen and (max-width: 1300px) {
    .scrollSpy {
        display: none;
    }
}

.value {
    padding-left: 4px;
    display: block;
    color: inherit !important;
}

.level-1 {
    margin-left: 6px
}

.level-2 {
    margin-left: 14px
}

.level-3 {
    margin-left: 22px
}

.value:hover {
    cursor: pointer;
    text-decoration: underline;
}

.selected {
    color: inherit !important;
    font-weight: bold;
    padding-left: 2px;
    border-left: 2px solid #1584ce
}