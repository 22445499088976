.post-img {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    height: auto;
}

.post-img > * {
    max-width: 85vw;
    margin-left: 50%;
    margin-right: -50%;
    transform: translateX(-50%);
    transition: .3s filter linear;
    filter: blur(0px);
    text-align: center;
}

.post-img.dark > .svg {
    filter: invert();
}

.blur {
    filter: blur(15px);
    opacity: 0.9;
}
