.snippetText > * {
    margin-right: 15px;
    margin-left: 15px;
}

.snippetText img {
    max-width: 100%;
    width: auto;
    height: auto;
    display: block;
}

.contentSkeleton {
    height: 22vh;
    width: 100%;
}

.snippetText {
    display: inline;
    line-height: 24px;
    font-size: 16px;
}

.snippetTitle {
    font-family: 'Segoe UI', 'Calibri', 'Droid Sans', sans-serif;
    font-size: 56px;
    font-weight: lighter;
    line-height: 80px;
    margin-top: 35px;
    margin-bottom: 25px;
}

.authorContainer {
}

.author {
}

.continue {
    position: absolute;
    bottom: 0;
    left: 0;
    backdrop-filter: blur(3px);
    width: 100%;
    text-align: center;
    padding: 15px;
    margin: 0;
    font-weight: bold;
}

